import { loadBlock } from '@bahn-wordpress/common/src/js/utils/block-loader'

const entryContent = document.body.querySelector('main.content-wrapper')

// eslint-disable-next-line
__webpack_public_path__ = '/wp-content/plugins/dbec-timeline/dist/'

loadBlock(
    () => import(/* webpackChunkName: 'timeline' */ './blocks/timeline'),
    '.timeline__container',
    entryContent,
)
